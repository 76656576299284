@import "react-big-calendar/lib/css/react-big-calendar.css";

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    background-color: unset;
    border: unset;
    padding-top: 0;
    padding-bottom: 0;
}


.rbc-day-slot .rbc-event-label {
    display: none;
}

.rbc-toolbar .rbc-toolbar-label {
    text-transform: uppercase;
    font-weight: bold;
}

.rbc-header {
    height: 25px;
    .rbc-button-link {
        font-size: medium;
    }
}

.rbc-btn-group {
    button {
        padding-bottom: 6px;
    }
}
.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: unset;
    padding: 0 !important;

}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: unset;
    padding: 0 !important;
}


.rbc-event, .rbc-day-slot .rbc-background-event {
    border-radius: 0;
}